<section id="home">

  <div id="rev_slider_14_1_wrapper" class="rev_slider_wrapper fullscreen-container" data-alias="gravitydesign1"
    data-source="gallery" style="background:#f1c98c;padding:0px;">
    <!-- START REVOLUTION SLIDER 5.4.1 fullscreen mode -->
    <div id="rev_slider_14_1" class="rev_slider fullscreenbanner" style="display:none;" data-version="5.4.1">
      <ul>
        <!-- SLIDE -->
        <li data-index="rs-32" data-transition="fade" data-slotamount="default" data-hideafterloop="0"
          data-hideslideonmobile="off" data-easein="default" data-easeout="default" data-masterspeed="300"
          data-rotate="0" data-saveperformance="off" data-title="Slide" data-param1="" data-param2="" data-param3=""
          data-param4="" data-param5="" data-param6="" data-param7="" data-param8="" data-param9="" data-param10=""
          data-description="">
          <!-- MAIN IMAGE -->
          <img src="assets/images/rs/img/transparent.png" data-bgcolor='#1d2931' style='background:#f1c98c' alt=""
            data-bgposition="center center" data-bgfit="cover" data-bgrepeat="no-repeat" data-bgparallax="off"
            class="rev-slidebg" data-no-retina>
          <!-- LAYERS -->

          <!-- LAYER NR. 1 -->
          <div class="tp-caption   tp-resizeme" id="slide-32-layer-1" data-x="['center','center','center','center']"
            data-hoffset="['0','0','0','0']" data-y="['bottom','bottom','bottom','bottom']"
            data-voffset="['0','0','0','0']" data-width="full-proportional" data-height="full-proportional"
            data-whitespace="nowrap" data-type="image" data-basealign="slide" data-responsive_offset="on"
            data-frames='[{"delay":500,"speed":2000,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
            data-textAlign="['inherit','inherit','inherit','inherit']" data-paddingtop="[0,0,0,0]"
            data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]"
            style="z-index: 5;"><img src="assets/images/rs/img/exp_bg2.jpg" alt=""
              data-ww="['full-proportional','full-proportional','full-proportional','full-proportional']"
              data-hh="['full-proportional','full-proportional','full-proportional','full-proportional']" width="1920"
              height="1280" data-no-retina> </div>

          <!-- LAYER NR. 2 -->
          <div class="tp-caption   tp-resizeme rs-parallaxlevel-1" id="slide-32-layer-8"
            data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']"
            data-y="['bottom','bottom','bottom','bottom']" data-voffset="['597','517','597','497']"
            data-fontsize="['120','120','120','80']" data-lineheight="['90','90','90','60']" data-width="none"
            data-height="none" data-whitespace="nowrap" data-type="text" data-responsive_offset="on"
            data-frames='[{"delay":1300,"speed":2000,"frame":"0","from":"sX:2;opacity:0;fb:20px;","to":"o:1;fb:0;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;fb:0;","ease":"Power3.easeInOut"}]'
            data-textAlign="['center','center','center','center']" data-paddingtop="[0,0,0,0]"
            data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]"
            style="z-index: 6; white-space: nowrap; font-size: 120px; line-height: 90px; font-weight: 400; color:rgb(196 28 28 / 84%);font-family: 'Skranji', cursive;letter-spacing:-1px;">
            <div class="rs-looped rs-wave" data-speed="3" data-angle="0" data-radius="2px" data-origin="50% 50%">
              VIMANIKA<br>TECHNOVATORZ </div>
          </div>

          <!-- LAYER NR. 3 -->
          <div class="tp-caption   tp-resizeme rs-parallaxlevel-1" id="slide-32-layer-7"
            data-x="['center','center','center','center']" data-hoffset="['-3','-3','-3','-3']"
            data-y="['bottom','bottom','bottom','bottom']" data-voffset="['600','520','600','500']"
            data-fontsize="['120','120','120','80']" data-lineheight="['90','90','90','60']" data-width="none"
            data-height="none" data-whitespace="nowrap" data-type="text" data-responsive_offset="on"
            data-frames='[{"delay":1200,"speed":2000,"frame":"0","from":"sX:2;opacity:0;fb:20px;","to":"o:1;fb:0;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;fb:0;","ease":"Power3.easeInOut"}]'
            data-textAlign="['center','center','center','center']" data-paddingtop="[0,0,0,0]"
            data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]"
            style="z-index: 7; white-space: nowrap; font-size: 120px; line-height: 90px; font-weight: 400; color: rgb(196 28 28 / 84%);font-family:'Skranji', cursive;letter-spacing:-1px;">
            <div class="rs-looped rs-wave" data-speed="3" data-angle="0" data-radius="2px" data-origin="50% 50%">
              VIMANIKA<br>TECHNOVATORZ </div>
          </div>

          <!-- LAYER NR. 4 -->
          <div class="tp-caption   tp-resizeme rs-parallaxlevel-1" id="slide-32-layer-5"
            data-x="['center','center','center','center']" data-hoffset="['3','3','3','3']"
            data-y="['bottom','bottom','bottom','bottom']" data-voffset="['600','520','600','500']"
            data-fontsize="['120','120','120','80']" data-lineheight="['90','90','90','60']" data-width="none"
            data-height="none" data-whitespace="nowrap" data-type="text" data-responsive_offset="on"
            data-frames='[{"delay":1100,"speed":2000,"frame":"0","from":"sX:2;opacity:0;fb:20px;","to":"o:1;fb:0;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;fb:0;","ease":"Power3.easeInOut"}]'
            data-textAlign="['center','center','center','center']" data-paddingtop="[0,0,0,0]"
            data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]"
            style="z-index: 8; white-space: nowrap; font-size: 120px; line-height: 90px; font-weight: 400; color: rgb(66, 49, 49);font-family:'Skranji', cursive;letter-spacing:-1px;">
            <div class="rs-looped rs-wave" data-speed="3" data-angle="0" data-radius="2px" data-origin="50% 50%">
              VIMANIKA<br>TECHNOVATORZ </div>
          </div>

          <!-- LAYER NR. 5 -->
          <div class="tp-caption   tp-resizeme rs-parallaxlevel-1" id="slide-32-layer-3"
            data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']"
            data-y="['bottom','bottom','bottom','bottom']" data-voffset="['600','520','600','500']"
            data-fontsize="['120','120','120','80']" data-lineheight="['90','90','90','60']" data-width="none"
            data-height="none" data-whitespace="nowrap" data-type="text" data-responsive_offset="on"
            data-frames='[{"delay":1000,"speed":2000,"frame":"0","from":"sX:2;opacity:0;fb:20px;","to":"o:1;fb:0;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;fb:0;","ease":"Power3.easeInOut"}]'
            data-textAlign="['center','center','center','center']" data-paddingtop="[0,0,0,0]"
            data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]"
            style="z-index: 9; white-space: nowrap; font-size: 120px; line-height: 90px; font-weight: 400; color: rgb(0, 0, 0);font-family:'Skranji', cursive;letter-spacing:-1px;">
            VIMANIKA<br>TECHNOVATORZ </div>

          <!-- LAYER NR. 6 -->
          <div class="tp-caption   tp-resizeme rs-parallaxlevel-1" id="slide-32-layer-15"
            data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']"
            data-y="['bottom','bottom','bottom','bottom']" data-voffset="['119','99','200','220']" data-width="none"
            data-height="none" data-whitespace="nowrap" data-type="image" data-responsive_offset="on"
            data-frames='[{"delay":850,"speed":2000,"frame":"0","from":"sX:1.1;sY:1.1;opacity:0;fb:20px;","to":"o:1;fb:0;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;fb:0;","ease":"Power3.easeInOut"}]'
            data-textAlign="['inherit','inherit','inherit','inherit']" data-paddingtop="[0,0,0,0]"
            data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]"
            style="z-index: 10;">
            <div class="rs-looped rs-wave" data-speed="5" data-angle="0" data-radius="3px" data-origin="50% 50%"><img
                src="assets/images/rs/img/cloud2.png" alt="" data-ww="['509px','509px','509px','407px']"
                data-hh="['250px','250px','250px','200px']" width="407" height="200" data-no-retina> </div>
          </div>

          <!-- LAYER NR. 7 -->
          <div class="tp-caption   tp-resizeme rs-parallaxlevel-2" id="slide-32-layer-2"
            data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']"
            data-y="['bottom','bottom','bottom','bottom']" data-voffset="['299','260','380','350']" data-width="none"
            data-height="none" data-whitespace="nowrap" data-type="image" data-responsive_offset="on"
            data-frames='[{"delay":250,"speed":5000,"frame":"0","from":"y:100px;rZ:15deg;opacity:0;fb:20px;","to":"o:1;fb:0;","ease":"Power4.easeOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;fb:0;","ease":"Power3.easeInOut"}]'
            data-textAlign="['inherit','inherit','inherit','inherit']" data-paddingtop="[0,0,0,0]"
            data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]"
            style="z-index: 11;">
            <div class="rs-looped rs-wave" data-speed="10" data-angle="0" data-radius="5px" data-origin="50% 50%"><img
                src="assets/images/uav-shadow.png" alt="" data-ww="['820px','718px','615px','410px']"
                data-hh="['400px','350px','300px','200px']" width="960" height="627" data-no-retina> </div>
          </div>

          <!-- LAYER NR. 8 -->
          <a class="tp-caption   tp-resizeme" href="#about" target="_blank" id="slide-32-layer-16"
            data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']"
            data-y="['bottom','bottom','bottom','bottom']" data-voffset="['230','210','310','310']"
            data-fontsize="['25','25','25','20']" data-lineheight="['25','25','25','20']"
            data-width="['none','none','none','320']" data-height="none"
            data-whitespace="['nowrap','nowrap','nowrap','normal']" data-type="text"
            data-actions='[{"event":"mouseenter","action":"startlayer","layer":"slide-32-layer-17","delay":""},{"event":"mouseleave","action":"stoplayer","layer":"slide-32-layer-17","delay":""}]'
            data-responsive_offset="on"
            data-frames='[{"delay":1000,"speed":1500,"frame":"0","from":"sX:2;opacity:0;fb:10px;","to":"o:1;fb:0;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;fb:0;","ease":"Power3.easeInOut"},{"frame":"hover","speed":"0","ease":"Linear.easeNone","to":"o:1;rX:0;rY:0;rZ:0;z:0;fb:0px;","style":"c:rgba(255,255,255,1);"}]'
            data-textAlign="['inherit','inherit','inherit','center']" data-paddingtop="[0,0,0,0]"
            data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]"
            style="z-index: 12; white-space: nowrap; font-size: 25px; line-height: 25px; font-weight: 300; color: rgb(3, 3, 3);font-family:Oswald;letter-spacing:5px;cursor:pointer;text-decoration: none;">
            Experience The Technology with Innovation of Knowledge </a>

          <!-- LAYER NR. 9 -->
          <div class="tp-caption tp-shape tp-shapewrapper  tp-resizeme" id="slide-32-layer-17"
            data-x="['center','center','center','center']" data-hoffset="['-4','-4','-4','-4']"
            data-y="['bottom','bottom','bottom','bottom']" data-voffset="['221','201','301','300']"
            data-width="['390','390','390','180']" data-height="2" data-whitespace="nowrap" data-type="shape"
            data-responsive_offset="on"
            data-frames='[{"delay":"bytrigger","speed":500,"frame":"0","from":"sX:0;opacity:1;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"bytrigger","speed":300,"frame":"999","to":"sX:0;opacity:1;","ease":"Power3.easeInOut"}]'
            data-textAlign="['inherit','inherit','inherit','inherit']" data-paddingtop="[0,0,0,0]"
            data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]"
            data-lasttriggerstate="reset" style="z-index: 13;background-color:rgb(11, 148, 228);"> </div>

          <!-- LAYER NR. 10 -->
          <div class="tp-caption   tp-resizeme rs-parallaxlevel-3" id="slide-32-layer-9"
            data-x="['center','center','center','center']" data-hoffset="['-531','-531','-348','-204']"
            data-y="['bottom','bottom','bottom','bottom']" data-voffset="['350','350','421','431']" data-width="none"
            data-height="none" data-whitespace="nowrap" data-type="image" data-responsive_offset="on"
            data-frames='[{"delay":250,"speed":3000,"frame":"0","from":"y:150px;rZ:90deg;opacity:0;fb:10px;","to":"o:1;fb:0;","ease":"Power4.easeOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;fb:0;","ease":"Power3.easeInOut"}]'
            data-textAlign="['inherit','inherit','inherit','inherit']" data-paddingtop="[0,0,0,0]"
            data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]"
            style="z-index: 14;">
            <div class="rs-looped rs-wave" data-speed="5" data-angle="0" data-radius="3px" data-origin="50% 50%"><img
                src="assets/images/airballoon-1.png" alt="" data-ww="['29px','29px','29px','29px']"
                data-hh="['21px','21px','21px','21px']" width="100" height="120" data-no-retina> </div>
          </div>

          <!-- LAYER NR. 11 -->
          <div class="tp-caption   tp-resizeme rs-parallaxlevel-3" id="slide-32-layer-10"
            data-x="['center','center','center','center']" data-hoffset="['493','493','326','194']"
            data-y="['bottom','bottom','bottom','bottom']" data-voffset="['399','399','408','438']" data-width="none"
            data-height="none" data-whitespace="nowrap" data-type="image" data-responsive_offset="on"
            data-frames='[{"delay":350,"speed":3000,"frame":"0","from":"y:150px;rZ:-90deg;opacity:0;fb:10px;","to":"o:1;fb:0;","ease":"Power4.easeOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;fb:0;","ease":"Power3.easeInOut"}]'
            data-textAlign="['inherit','inherit','inherit','inherit']" data-paddingtop="[0,0,0,0]"
            data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]"
            style="z-index: 15;">
            <div class="rs-looped rs-wave" data-speed="5" data-angle="0" data-radius="3px" data-origin="50% 50%"><img
                src="assets/images/airballoon-2.png" alt="" data-ww="['28px','28px','28px','28px']"
                data-hh="['27px','27px','27px','27px']" width="100" height="120" data-no-retina> </div>
          </div>

          <!-- LAYER NR. 12 -->
          <div class="tp-caption   tp-resizeme rs-parallaxlevel-5" id="slide-32-layer-11"
            data-x="['center','center','center','center']" data-hoffset="['350','350','245','141']"
            data-y="['bottom','bottom','bottom','bottom']" data-voffset="['338','338','331','348']" data-width="none"
            data-height="none" data-whitespace="nowrap" data-type="image" data-responsive_offset="on"
            data-frames='[{"delay":450,"speed":3000,"frame":"0","from":"y:150px;rZ:45deg;opacity:0;fb:10px;","to":"o:1;fb:0;","ease":"Power4.easeOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;fb:0;","ease":"Power3.easeInOut"}]'
            data-textAlign="['inherit','inherit','inherit','inherit']" data-paddingtop="[0,0,0,0]"
            data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]"
            style="z-index: 16;">
            <div class="rs-looped rs-wave" data-speed="3" data-angle="0" data-radius="2px" data-origin="50% 50%"><img
                src="assets/images/airballoon-3.png" alt="" data-ww="['26px','26px','26px','26px']"
                data-hh="['24px','24px','24px','24px']" width="100" height="130" data-no-retina> </div>
          </div>

          <!-- LAYER NR. 13 -->
          <div class="tp-caption   tp-resizeme rs-parallaxlevel-5" id="slide-32-layer-12"
            data-x="['center','center','center','center']" data-hoffset="['-455','-455','-321','-214']"
            data-y="['bottom','bottom','bottom','bottom']" data-voffset="['320','320','357','381']" data-width="none"
            data-height="none" data-whitespace="nowrap" data-type="image" data-responsive_offset="on"
            data-frames='[{"delay":550,"speed":3000,"frame":"0","from":"y:150px;rZ:-125deg;opacity:0;fb:10px;","to":"o:1;fb:0;","ease":"Power4.easeOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;fb:0;","ease":"Power3.easeInOut"}]'
            data-textAlign="['inherit','inherit','inherit','inherit']" data-paddingtop="[0,0,0,0]"
            data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]"
            style="z-index: 17;">
            <div class="rs-looped rs-wave" data-speed="5" data-angle="0" data-radius="4px" data-origin="50% 50%"><img
                src="assets/images/airballoon-4.png" alt="" data-ww="['27px','27px','27px','27px']"
                data-hh="['26px','26px','26px','26px']" width="90" height="114" data-no-retina> </div>
          </div>

          <!-- LAYER NR. 14 -->
          <div class="tp-caption   tp-resizeme rs-parallaxlevel-4" id="slide-32-layer-13"
            data-x="['center','center','center','center']" data-hoffset="['-88','-88','-88','-37']"
            data-y="['bottom','bottom','bottom','bottom']" data-voffset="['382','382','382','399']" data-width="none"
            data-height="none" data-whitespace="nowrap" data-type="image" data-responsive_offset="on"
            data-frames='[{"delay":650,"speed":3000,"frame":"0","from":"y:150px;rZ:125deg;opacity:0;fb:10px;","to":"o:1;fb:0;","ease":"Power4.easeOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;fb:0;","ease":"Power3.easeInOut"}]'
            data-textAlign="['inherit','inherit','inherit','inherit']" data-paddingtop="[0,0,0,0]"
            data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]"
            style="z-index: 18;">
            <div class="rs-looped rs-wave" data-speed="6" data-angle="0" data-radius="3px" data-origin="50% 50%"><img
                src="assets/images/airballoon-5.png" alt="" data-ww="['13','13','13','13px']" data-hh="12px" width="94"
                height="100" data-no-retina> </div>
          </div>

          <!-- LAYER NR. 15 -->
          <div class="tp-caption   tp-resizeme rs-parallaxlevel-4" id="slide-32-layer-14"
            data-x="['center','center','center','center']" data-hoffset="['-232','-232','-232','-170']"
            data-y="['bottom','bottom','bottom','bottom']" data-voffset="['300','300','300','366']" data-width="none"
            data-height="none" data-whitespace="nowrap" data-type="image" data-responsive_offset="on"
            data-frames='[{"delay":750,"speed":3000,"frame":"0","from":"y:150px;rZ:-125deg;opacity:0;fb:10px;","to":"o:1;fb:0;","ease":"Power4.easeOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;fb:0;","ease":"Power3.easeInOut"}]'
            data-textAlign="['inherit','inherit','inherit','inherit']" data-paddingtop="[0,0,0,0]"
            data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]"
            style="z-index: 19;">
            <div class="rs-looped rs-wave" data-speed="7" data-angle="0" data-radius="6px" data-origin="50% 50%"><img
                src="assets/images/airballoon-6.png" alt="" data-ww="['16px','16px','16px','16px']"
                data-hh="['20px','20px','20px','20px']" width="74" height="101" data-no-retina> </div>
          </div>

          <!-- LAYER NR. 16 -->
          <a class="tp-caption   tp-resizeme" href="https://revolution.themepunch.com/direct-customer-benefits/"
            target="_blank" id="slide-32-layer-18" data-x="['center','center','center','center']"
            data-hoffset="['-270','-270','-230','0']" data-y="['bottom','bottom','bottom','bottom']"
            data-voffset="['100','100','200','210']" data-fontsize="['30','30','25','25']"
            data-lineheight="['28','28','23','23']" data-width="270" data-height="none" data-whitespace="nowrap"
            data-type="text" data-actions='' data-responsive_offset="on"
            data-frames='[{"delay":1400,"speed":1500,"frame":"0","from":"sX:2;opacity:0;fb:10px;","to":"o:1;fb:0;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;fb:0;","ease":"Power3.easeInOut"},{"frame":"hover","speed":"0","ease":"Linear.easeNone","to":"o:1;rX:0;rY:0;rZ:0;z:0;fb:0px;","style":"c:rgba(255,255,255,1);"}]'
            data-textAlign="['center','center','center','center']" data-paddingtop="[0,0,0,0]"
            data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]"
            style="z-index: 20; min-width: 270px; max-width: 270px; white-space: nowrap; font-size: 30px; line-height: 28px; font-weight: 300; color: rgba(255,255,255,1);font-family:Oswald;cursor:pointer;text-decoration: none;">
            <!-- 100% MADE WITH<br>SLIDER REVOLUTION  -->
          </a>

          <!-- LAYER NR. 17 -->
          <a class="tp-caption   tp-resizeme"
            href="https://revolution.themepunch.com/direct-customer-benefits/#templatelibrary" target="_blank"
            id="slide-32-layer-19" data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']"
            data-y="['bottom','bottom','bottom','bottom']" data-voffset="['100','100','200','150']"
            data-fontsize="['30','30','25','25']" data-lineheight="['28','28','23','23']" data-width="270"
            data-height="none" data-whitespace="nowrap" data-type="text" data-actions='' data-responsive_offset="on"
            data-frames='[{"delay":1400,"speed":1500,"frame":"0","from":"sX:2;opacity:0;fb:10px;","to":"o:1;fb:0;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;fb:0;","ease":"Power3.easeInOut"},{"frame":"hover","speed":"0","ease":"Linear.easeNone","to":"o:1;rX:0;rY:0;rZ:0;z:0;fb:0px;","style":"c:rgba(255,255,255,1);"}]'
            data-textAlign="['center','center','center','center']" data-paddingtop="[0,0,0,0]"
            data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]"
            style="z-index: 21; min-width: 270px; max-width: 270px; white-space: nowrap; font-size: 30px; line-height: 28px; font-weight: 300; color: rgba(255,255,255,1);font-family:Oswald;cursor:pointer;text-decoration: none;">
            <!-- DOWNLOAD THIS<br>TEMPLATE FOR FREE  -->
          </a>

          <!-- LAYER NR. 18 -->
          <a class="tp-caption   tp-resizeme" href="https://revolution.themepunch.com/examples/" target="_blank"
            id="slide-32-layer-20" data-x="['center','center','center','center']" data-hoffset="['270','270','230','0']"
            data-y="['bottom','bottom','bottom','bottom']" data-voffset="['100','100','200','90']"
            data-fontsize="['30','30','25','25']" data-lineheight="['28','28','23','23']" data-width="270"
            data-height="none" data-whitespace="nowrap" data-type="text" data-actions='' data-responsive_offset="on"
            data-frames='[{"delay":1400,"speed":1500,"frame":"0","from":"sX:2;opacity:0;fb:10px;","to":"o:1;fb:0;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;fb:0;","ease":"Power3.easeInOut"},{"frame":"hover","speed":"0","ease":"Linear.easeNone","to":"o:1;rX:0;rY:0;rZ:0;z:0;fb:0px;","style":"c:rgba(255,255,255,1);"}]'
            data-textAlign="['center','center','center','center']" data-paddingtop="[0,0,0,0]"
            data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]"
            style="z-index: 22; min-width: 270px; max-width: 270px; white-space: nowrap; font-size: 30px; line-height: 28px; font-weight: 300; color: rgba(255,255,255,1);font-family:Oswald;cursor:pointer;text-decoration: none;">
            <!--85+ TEMPLATES<br>AVAILABLE IN LIBRARY  -->
          </a>

          <!-- LAYER NR. 19 -->
          <div class="tp-caption   tp-resizeme" id="slide-32-layer-21" data-x="['center','center','center','center']"
            data-hoffset="['0','0','0','0']" data-y="['bottom','bottom','bottom','bottom']"
            data-voffset="['50','50','50','30']" data-width="['none','none','none','360']" data-height="none"
            data-whitespace="['nowrap','nowrap','nowrap','normal']" data-type="text" data-basealign="slide"
            data-responsive_offset="on"
            data-frames='[{"delay":1400,"speed":1500,"frame":"0","from":"sX:2;opacity:0;fb:10px;","to":"o:1;fb:0;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;fb:0;","ease":"Power3.easeInOut"}]'
            data-textAlign="['center','center','center','center']" data-paddingtop="[0,0,0,0]"
            data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]"
            style="z-index: 23; white-space: nowrap; font-size: 15px; line-height: 15px; font-weight: 300; color: rgba(12,17,21,1);font-family:Oswald;letter-spacing:10px;cursor:pointer;">
            <!-- Copyright 2018 THEMEPUNCH OHG  -->
          </div>
        </li>
      </ul>
      <div class="tp-bannertimer tp-bottom" style="visibility: hidden !important;"></div>
    </div>
  </div><!-- END REVOLUTION SLIDER -->

</section>

<!-- ABOUT SECTION -->
<section id="about" class="container t-center py">
  <!-- Title -->
  <h4 class="playfair italic light gray7">Welcome To Vimanika</h4>
  <!-- Title Slider Texts - For #type span. -->
  <div id="type-get">
    <p>With Vimanika.</p>
    <p>With Our Coaches.</p>
    <p>With Awesome Trainings.</p>
  </div>
  <h1 class="extrabold-title">Feel like a hero. Live a better life. <span id="type" class="playfair italic"></span></h1>
  <!-- Description -->
  <p class="lh-lg font-16 xs-mt gray7 font-13-mobile">
    Vimanika Technovatorz has established on Jan 2019 at Coimbatore, Tamil
    Nadu by Aeronautical Engineers. It is mainly developed on the basis of training
    firm by providing quality training to the students on Aero-modeling, UAV piloting,
    3D Printing and also we afford a training courses on Design and Simulation
    software’s. Then later we elevated to the engineering services mainly on the design
    and fabrication of customized drones which are one of the most wonderful and
    precious advancement of technology. The main motive of our company is to
    provide an innovative and excellent services in the field of UAV technologies. We
    give high respect to the humanity so VT is also providing life skill training like
    Kalaripayattu (Traditional Martial Arts), Yoga, Flute and Bharathanatiyam to make
    the humans strength mentally as well as physically.
  </p>

  <!-- Divider -->
  <div class="divider-3 container-xs font-15 uppercase extrabold xs-mt">
    <span><i class="fa fa-eye font-25 colored"></i></span>
  </div>
  <!-- Description -->
  <p class="lh-lg font-16 xs-mt gray7 font-13-mobile">
    Our vision is to create Hands-on, Innovative learning environments where students are Educated, Fostered,
    and Empowered through their innovative ideas in the emerging Research and Aviation Technologies. We provide
    Life Leading training to the learners thereby bring up them to be Physically-Mentally strong,
    Mindfulness, and Self-Realized persons in their day to day life.
  </p>

  <!-- Divider -->
  <div class="divider-3 container-xs font-15 uppercase extrabold xs-mt">
    <span><i class="fa fa-bullseye font-25 colored"></i></span>
  </div>
  <!-- Description -->
  <p class="lh-lg font-16 xs-mt gray7 font-13-mobile">
    Our mission is to develop the elevated Quality and Innovative Drones and to provide pragmatic experience to
    the students to the next generation of UAVs and Research fields through technology. We progress the students
    as creators and innovators in the future Tech-Driven world.We provide Life Leading skills to the learners
    and transit them to become Self-Realized persons to serve for
    the welfare of the society.
  </p>

  <div class="boxes container sm-mt t-left">
    <div class="row clearfix">
      <!-- Box -->
      <div class="col-md-4 col-sm-6 col-12 xs-mt-mobile">
        <div class="row">
          <!-- Icon -->
          <div class="col-4 t-right colored1 xxs-mt">
            <i class="fa fa-superpowers fa-4x"></i>
          </div>
          <div class="col-8">
            <div class="fact" data-source="5">
              <div>
                <h1 class="factor colored extrabold-title italic playfair">5</h1>
              </div>
            </div>
            <h5 class="italic gray5">Products designed.</h5>
          </div>
        </div>
      </div>
      <!-- Box -->
      <div class="col-md-4 col-sm-6 col-12 xs-mt-mobile">
        <div class="row">
          <!-- Icon -->
          <div class="col-4 t-right colored1 xxs-mt">
            <i class="fa fa-ravelry fa-4x"></i>
          </div>
          <div class="col-8">
            <div class="fact" data-source="4">
              <div>
                <h1 class="factor colored extrabold-title italic playfair">4</h1>
              </div>
            </div>
            <h5 class="italic gray6">Projects completed.</h5>
          </div>
        </div>
      </div>
      <!-- Box -->
      <div class="col-md-4 col-sm-6 col-12 xs-mt-mobile">
        <div class="row">
          <!-- Icon -->
          <div class="col-4 t-right colored1 xxs-mt">
            <i class="fa fa-thermometer-4 fa-4x"></i>
          </div>
          <div class="col-8">
            <div class="fact" data-source="30">
              <div>
                <h1 class="factor colored extrabold-title italic playfair">30</h1>
              </div>
            </div>
            <h5 class="italic gray6">Trusted Customers.</h5>
          </div>
        </div>
      </div>
      <!-- Box -->
      <!-- <div class="col-md-3 col-sm-6 col-12 xs-mt-mobile">
        <div class="row">
          <div class="col-4 t-right colored1 xxs-mt">
            <i class="fa fa-shower fa-4x"></i>
          </div>
          <div class="col-8">
            <div class="fact" data-source="400">
              <div>
                <h1 class="factor colored extrabold-title italic playfair">400</h1>
              </div>
            </div>
            <h5 class="italic gray6">Total Acres served.</h5>
          </div>
        </div>
      </div> -->
    </div>
  </div>


</section>
