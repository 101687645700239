<section id="home" class="sm-py bg-gray1 bb-1 border-gray1 fullwidth">
  <!-- Container -->
  <div class="container">
    <div class="row calculate-height">
      <div class="t-left t-center-xs col-sm-6 col-xs-12">
        <h2 class="normal-subtitle">Life-Style</h2>
        <h5 class="gray7">Don't just work & stress we should also relax & enjoy...</h5>
      </div>
      <div class="t-right t-center-xs col-sm-6 col-xs-12">
        <ol class="breadcrumb transparent no-pm v-center font-12">
          <li class="breadcrumb-item black-hover gray8 slow"><a routerLink="/home">Home</a></li>
          <li class="breadcrumb-item gray6 c-default">Life style</li>
        </ol>
      </div>
    </div>
  </div>
  <!-- End Container -->
</section>

<!-- Overlap Image Type -->
<section id="overlap-images" class="py">

  <!-- Container for title -->
  <div class="container t-center">
    <!-- Title -->
    <h2 class="bold-title">Life style we have</h2>
    <p class="xxs-my font-14 gray7">
      We work, we enjoy, we play, we're good...
    </p>
  </div>
  <!-- End Container for title -->

  <!-- Container for Images -->
  <div class="container sm-mt">
    <!-- Row -->
    <div class="row">
      <!-- Col -->
      <div class="col-md-8 offset-md-2 col-12">
        <!-- Overlap Images -->
        <div class="overlap-images">
          <!-- Item -->
          <div class="item zi-2">
            <!-- Re-position your image -->
            <div
              style="-webkit-transform:translate(15%, -15%); -ms-transform:translate(15%, -15%); transform:translate(15%, -15%);">
              <!-- Choose your animation, speed and box shadow -->
              <div class="animated fast bs-xl fadeInUp" data-animation="fadeInUp" data-animation-delay="300">
                <!-- Your image -->
                <img src="assets/images/flute.jpg" alt="flute img">
              </div>
            </div>
          </div>
          <!-- End Item -->
          <!-- Item -->
          <div class="item zi-4">
            <!-- Re-position your image -->
            <div
              style="-webkit-transform:translate(-30%, 0%); -ms-transform:translate(-30%, 0%); transform:translate(-30%, 0%);">
              <!-- Choose your animation, speed and box shadow -->
              <div class="animated fast bs-xl fadeInLeft" data-animation="fadeInLeft" data-animation-delay="500">
                <!-- Your image -->
                <img src="assets/images/kalari.jpg" alt="kalari martial arts">
              </div>
            </div>
          </div>
          <!-- End Item -->
          <!-- Item -->
          <div class="item zi-3">
            <!-- Re-position your image -->
            <div
              style="-webkit-transform:translate(35%, 40%); -ms-transform:translate(35%, 40%); transform:translate(35%, 40%);">
              <!-- Choose your animation, speed and box shadow -->
              <div class="animated fast bs-xl fadeInRight" data-animation="fadeInRight" data-animation-delay="700">
                <!-- Your image -->
                <img src="assets/images/muralidharan.png" alt="dance of nation">
              </div>
            </div>
          </div>
          <!-- End Item -->
        </div>
        <!-- End Overlap Images -->
      </div>
      <!-- End Col -->
    </div>
    <!-- End Row -->
  </div>
  <!-- End Container for Images -->
</section>
<!-- End Overlap Image Type -->

<!-- Overlap Image Type -->
<section class="py bt-1 bb-1 border-gray2 bg-gray">
  <!-- Container for Images -->
  <div class="container">
    <!-- Row -->
    <div class="row">
      <!-- Col -->
      <div class="col-md-5 col-12 height-auto-mobile">
        <!-- Overlap Images -->
        <div class="overlap-images">
          <!-- Item -->
          <div class="item zi-2">
            <!-- Re-position your image -->
            <div
              style="-webkit-transform:translate(-10%, 0%); -ms-transform:translate(-10%, 0%); transform:translate(-10%, 0%);">
              <!-- Choose your animation, speed and box shadow -->
              <div class="animated fast bs-xl" data-animation="zoomIn" data-animation-delay="300">
                <!-- Your image -->
                <img src="assets/images/flute.jpg" alt="flute-2">
              </div>
            </div>
          </div>
          <!-- End Item -->
          <!-- Item -->
          <div class="item zi-3">
            <!-- Re-position your image -->
            <div
              style="-webkit-transform:translate(10%, 30%); -ms-transform:translate(10%, 30%); transform:translate(10%, 30%);">
              <!-- Choose your animation, speed and box shadow -->
              <div class="animated fast bs-xl" data-animation="zoomIn" data-animation-delay="500">
                <!-- Your image -->
                <img src="assets/images/flute.png" alt="flute-1">
              </div>
            </div>
          </div>
          <!-- End Item -->
        </div>
        <!-- End Overlap Images -->
      </div>
      <!-- End Col -->
      <!-- Col -->
      <div class="col-md-7 col-12 t-right t-center-mobile height-auto-mobile">
        <!-- Align center -->
        <div class="v-center v-normal-mobile sm-mt-mobile pl no-pl-mobile">
          <!-- Title -->
          <h2 class="bold-title">Playing Flute.</h2>
          <p class="xxs-my font-14 gray7 lh-lg">
            The flute is a family of musical instruments in the woodwind group. Unlike woodwind instruments with reeds,
            a flute is an aerophone or reedless wind instrument that produces its sound from the flow of air across an
            opening.
          </p>
        </div>
      </div>
      <!-- End Col -->
    </div>
    <!-- End Row -->
  </div>
  <!-- End Container for Images -->
</section>
<!-- End Overlap Image Type -->

<!-- Overlap Image Type -->
<section class="py">
  <!-- Container for Images -->
  <div class="container">
    <!-- Row -->
    <div class="row">
      <!-- Col -->
      <div class="col-md-7 col-12 t-left t-center-mobile height-auto-mobile">
        <!-- Align center -->
        <div class="v-center v-normal-mobile pr no-pr-mobile">
          <!-- Title -->
          <h3 class="bold-subtitle">Martial Arts - Kalari.</h3>
          <p class="xxs-my font-14 gray7 lh-lg">
            &nbsp;&nbsp;&nbsp;&nbsp; Kalaripayattu involves punches, kicks, wrestling, preset styles, arms and healing
            techniques. Regional
            variations are categorized by geographical position, Northern Kalaripayattu, or "Vadakkan Kalari" is based
            on elegant and flexible movements, evasions, jumps and weapons training, while the southern "Thekkan Kalari"
            style specializes in hard, impact based techniques with priority on hand-to-hand combat and pressure point
            strikes.<br /> &nbsp;&nbsp;&nbsp;&nbsp;
            Kalaripayattu teachers often provide uzhichil with medicinal oils to their students in order to increase
            their physical flexibility or to treat muscular injuries. As a result of learning about the human body,
            Indian martial artists became knowledgeable in the fields of traditional medicine and massage.
          </p>
        </div>
      </div>
      <!-- End Col -->
      <!-- Col -->
      <div class="col-md-5 col-12 height-auto-mobile">
        <!-- Overlap Images -->
        <div class="overlap-images">
          <!-- Item -->
          <div class="item zi-4">
            <!-- Re-position your image -->
            <div
              style="-webkit-transform:translate(-10%, 0%); -ms-transform:translate(-10%, 0%); transform:translate(-10%, 0%);">
              <!-- Choose your animation, speed and box shadow -->
              <div class="animated" data-animation="flipInY" data-animation-delay="300">
                <!-- Your image -->
                <img src="assets/images/kalari.jpg" alt="Overlap image" class="radius-lg no-radius-hover slow bs-xl">
              </div>
            </div>
          </div>
          <!-- End Item -->
          <!-- Item -->
          <div class="item zi-3">
            <!-- Re-position your image -->
            <div
              style="-webkit-transform:translate(20%, 3%); -ms-transform:translate(20%, 3%); transform:translate(20%, 3%);">
              <!-- Choose your animation, speed and box shadow -->
              <div class="animated" data-animation="fadeInRight" data-animation-delay="1100">
                <div class="width-320 height-240 bg-transparent border-2 border-dashed border-warning"></div>
              </div>
            </div>
          </div>
          <!-- End Item -->
          <!-- Item -->
          <div class="item zi-2">
            <!-- Re-position your image -->
            <div
              style="-webkit-transform:translate(10%, 30%); -ms-transform:translate(10%, 30%); transform:translate(10%, 30%);">
              <!-- Choose your animation, speed and box shadow -->
              <div class="animated" data-animation="flipInX" data-animation-delay="700">
                <!-- Your image -->
                <img src="assets/images/muralidharan.png" alt="Overlap image" class="radius-xl bs-xl">
              </div>
            </div>
          </div>
          <!-- End Item -->
        </div>
        <!-- End Overlap Images -->
      </div>
      <!-- End Col -->
    </div>
    <!-- End Row -->
  </div>
  <!-- End Container for Images -->
</section>
<!-- End Overlap Image Type -->

<!-- Overlap Image Type -->
<section class="hide-comp py bt-1 bb-1 border-gray2 bg-gray">
  <!-- Container for Images -->
  <div class="container">
    <!-- Row -->
    <div class="row">
      <!-- Col -->
      <div class="col-md-5 col-12 height-auto-mobile">
        <!-- Overlap Images -->
        <div class="overlap-images">
          <!-- Item -->
          <div class="item zi-2">
            <!-- Re-position your image -->
            <div
              style="-webkit-transform:translate(-10%, -10%); -ms-transform:translate(-10%, -10%); transform:translate(-10%, -10%);">
              <!-- Choose your animation, speed and box shadow -->
              <div class="animated fast bs-inset" data-animation="rotateInDownLeft" data-animation-delay="1000">
                <div class="basic-mark to-left bold uppercase font-10 radius-lg bg-info white bs">Fresh</div>
                <!-- Your image -->
                <img src="assets/images/blocks/overlap_image_01.jpg" alt="Overlap image" class="grayscale">
              </div>
            </div>
          </div>
          <!-- End Item -->
          <!-- Item -->
          <div class="item zi-3">
            <!-- Re-position your image -->
            <div
              style="-webkit-transform:translate(10%, 20%); -ms-transform:translate(10%, 20%); transform:translate(10%, 20%);">
              <!-- Choose your animation, speed and box shadow -->
              <div class="animated fast bs-inset" data-animation="rotateInDownLeft" data-animation-delay="600">
                <div class="basic-mark to-right bold uppercase font-10 radius-lg bg-danger white bs">Beautiful</div>
                <!-- Your image -->
                <img src="assets/images/dancing.jpg" alt="Overlap image" class="grayscale">
              </div>
            </div>
          </div>
          <!-- End Item -->
        </div>
        <!-- End Overlap Images -->
      </div>
      <!-- End Col -->
      <!-- Col -->
      <div class="col-md-7 col-12 t-right t-center-mobile height-auto-mobile">
        <!-- Align center -->
        <div class="v-center v-normal-mobile xs-mt-mobile pl no-pl-mobile">
          <!-- Title -->
          <h3 class="bold-subtitle">Dancing.</h3>
          <p class="xxs-my font-14 gray7 lh-lg">
            There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in
            some form, by injected humour, or randomised words which don't look even slightly believable. If you are
            going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the
            middle of text. All the Lorem Ipsum generators on the Internet as necessary.
          </p>
        </div>
      </div>
      <!-- End Col -->
    </div>
    <!-- End Row -->
  </div>
  <!-- End Container for Images -->
</section>
<!-- End Overlap Image Type -->

<!-- Overlap Image Type -->
<section class="hide-comp py">
  <!-- Container for Images -->
  <div class="container">
    <!-- Row -->
    <div class="row">
      <!-- Col -->
      <div class="col-md-7 col-12 t-left t-center-mobile height-auto-mobile">
        <!-- Align center -->
        <div class="v-center v-normal-mobile pr no-pr-mobile">
          <!-- Title -->
          <h2 class="bold-title">Musics.</h2>
          <p class="xxs-my font-14 gray7 lh-lg">
            There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in
            some form, by injected humour, or randomised words which don't look even slightly believable. If you are
            going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the
            middle of text. All the Lorem Ipsum generators on the Internet as necessary.
          </p>
        </div>
      </div>
      <!-- End Col -->
      <!-- Col -->
      <div class="col-md-5 col-12 height-auto-mobile">
        <!-- Overlap Images -->
        <a href="" target="_blank" class="overlap-images block qdr-hover-4" data-toggle="tooltip" data-placement="top"
          data-animation="true" title="Use for links">
          <!-- Item -->
          <div class="item zi-1">
            <!-- Re-position your image -->
            <div
              style="-webkit-transform:translate(0%, -10%); -ms-transform:translate(0%, -10%); transform:translate(0%, -10%);">
              <!-- Choose your animation, speed and box shadow -->
              <div class="animated fast bs-inset" data-animation="fadeInDown" data-animation-delay="400">
                <!-- Your image -->
                <img src="assets/images/blocks/overlap_image_01.jpg" alt="Overlap image">
              </div>
            </div>
          </div>
          <!-- End Item -->
          <!-- Item -->
          <div class="item zi-3">
            <!-- Re-position your image -->
            <div
              style="-webkit-transform:translate(20%, 20%); -ms-transform:translate(20%, 20%); transform:translate(20%, 20%);">
              <!-- Choose your animation, speed and box shadow -->
              <div class="animated fast bs-inset" data-animation="fadeInRight" data-animation-delay="800">
                <div class="basic-mark to-right bold uppercase font-10 radius-lg bg-danger white bs">Beautiful</div>
                <!-- Your image -->
                <img src="assets/images/blocks/overlap_image_03.jpg" alt="Overlap image">
              </div>
            </div>
          </div>
          <!-- End Item -->
          <!-- Item -->
          <div class="item zi-2">
            <!-- Re-position your image -->
            <div
              style="-webkit-transform:translate(-20%, 40%); -ms-transform:translate(-20%, 40%); transform:translate(-20%, 40%);">
              <!-- Choose your animation, speed and box shadow -->
              <div class="animated fast bs-inset" data-animation="fadeInLeft" data-animation-delay="600">
                <!-- Your image -->
                <img src="assets/images/music.jpg" alt="Overlap image">
              </div>
            </div>
          </div>
          <!-- End Item -->
        </a>
        <!-- End Overlap Images -->
      </div>
      <!-- End Col -->
    </div>
    <!-- End Row -->
  </div>
  <!-- End Container for Images -->
</section>
