<section id="training-home" class="sm-py bg-gray1 bb-1 border-gray1 fullwidth">
  <!-- Container -->
  <div class="container">
    <div class="row calculate-height">
      <div class="t-left t-center-xs col-sm-6 col-xs-12">
        <h2 class="normal-subtitle">Training's & Seminar's</h2>
        <h5 class="gray7">All available trainings, internships and seminars are show cased here.</h5>
      </div>
      <div class="t-right t-center-xs col-sm-6 col-xs-12">
        <ol class="breadcrumb transparent no-pm v-center font-12">
          <li class="breadcrumb-item black-hover gray8 slow"><a routerLink="/home">Home</a></li>
          <li class="breadcrumb-item gray6 c-default">Training</li>
        </ol>
      </div>
    </div>
  </div>
  <!-- End Container -->
</section>

<section id="training">
  <!-- Divider -->
  <!-- <div class="divider-1 font-11 uppercase container extrabold mt">
    <span>Trainings</span>
  </div> -->

  <div class="qdr-col-3 container t-center gray8 sm-py">
    <!-- Box -->
    <div>
      <!-- Box Border -->
      <div class="gray8 border-1 border-gray no-border-bottom radius o-hidden slow bs-light-hover relative">
        <!-- Mark -->
        <div class="basic-mark white radius bg-info">
          New!
        </div>
        <div class="xs-py">
          <p>
            <img src="assets/images/military-drone-concept.png" alt="">
          </p>
          <h4 class="extrabold no-pm xs-mt">UAV Training</h4>
          <h5>Master of UAV feilds</h5>
          <!-- Price -->
          <h3 class="hide-comp">
            ₹
            <span class="text-lg2 extrabold">19</span><span class="merriweather italic light font-17">Monthly</span>
          </h3>
        </div>

        <p class="sm-15">
          By this we enhance the student’s knowledge on the UAV field. For better outcome & experience students are
          engaged to hands on training, basic terms and working principles of the components used on drone.
        </p>

        <!-- List -->
        <ul class="hide-comp basic-list list-lg xs-mb">
          <li>PREMIUM PROFILE LISTING</li>
          <li>UNLIMITED FILE ACCESS</li>
          <li>FREE APPOINTMENTS</li>
          <li>2 SUBDOMAINS</li>
        </ul>
        <!-- <div>
          <a href="#" class="xxs-py block font-11 uppercase white bold bg-colored bg-colored-hover slow">Select
            This Plan</a>
        </div> -->
      </div>
    </div>

    <!-- Box -->
    <div>
      <!-- Box Border -->
      <div class="border-1 border-gray no-border-bottom radius o-hidden slow bg-gradient white bs-light-hover relative">
        <!-- Mark -->
        <div class="basic-mark white radius bg-danger">
          Hot!
        </div>
        <div class="xs-py">
          <p>
            <img src="assets/images/piolating-concept.png" alt="">
          </p>
          <h4 class="extrabold no-pm xs-mt">Piloting Training</h4>
          <h5>Call me Pilot</h5>
          <!-- Price -->
          <h3 class="hide-comp">
            ₹
            <span class="text-lg2 extrabold">39</span><span class="merriweather italic light font-17">Monthly</span>
          </h3>
        </div>

        <p class="sm-15">
          For better flying the piloting skill is very important, on behalf of this we provide complete piloting
          training from basic principle of the vehicle through Simulation in initial.
        </p>
        <!-- List -->
        <ul class="hide-comp basic-list list-lg xs-mb">
          <li>PREMIUM PROFILE LISTING</li>
          <li>UNLIMITED FILE ACCESS</li>
          <li>FREE APPOINTMENTS</li>
          <li>2 SUBDOMAINS</li>
        </ul>
        <!-- <div>
          <a href="#" class="xxs-py block font-11 uppercase white bold bg-dark3 slow">Select This Plan</a>
        </div> -->
      </div>
    </div>

    <!-- Box -->
    <div>
      <!-- Box Border -->
      <div class="gray8 border-1 border-gray no-border-bottom radius o-hidden slow bs-light-hover relative">
        <!-- Mark -->
        <div class="basic-mark white radius bg-info">
          New!
        </div>
        <div class="xs-py">
          <p>
            <img src="assets/images/aero-modeling.png" alt="">
          </p>
          <h4 class="extrabold no-pm xs-mt">Aero Modelling</h4>
          <h5>An art by engineers</h5>
          <!-- Price -->
          <h3 class="hide-comp">
            ₹
            <span class="text-lg2 extrabold">59</span><span class="merriweather italic light font-17">Monthly</span>
          </h3>
        </div>

        <p class="sm-15">
          Aero Modelling is too an art and profession for the students, and it’s the bottom basic of the aerodynamic
          force of aircraft. We include an Aero Modelling Club on colleges for their practical knowledge skill on
          Aerodynamic.
        </p>

        <!-- List -->
        <ul class="hide-comp basic-list list-lg xs-mb">
          <li>PREMIUM PROFILE LISTING</li>
          <li>UNLIMITED FILE ACCESS</li>
          <li>FREE APPOINTMENTS</li>
          <li>2 SUBDOMAINS</li>
        </ul>
        <!-- <div>
          <a href="#" class="xxs-py block font-11 uppercase white bold bg-colored bg-colored-hover slow">Select
            This Plan</a>
        </div> -->
      </div>
    </div>

  </div>
</section>

<section id="training">
  <!-- Divider -->
  <div class="divider-1 font-11 uppercase container extrabold mt">
    <span>Exclusively for students</span>
  </div>

  <div class="qdr-col-3 container t-center gray8 sm-py">
    <!-- Box -->
    <div>
      <!-- Box Border -->
      <div class="gray8 border-1 border-gray no-border-bottom radius o-hidden slow bs-light-hover relative">
        <!-- Mark -->
        <!-- <div class="basic-mark white radius bg-warning">
          Hot!
        </div> -->
        <div class="xs-py">
          <p>
            <img src="assets/images/internship-concept.png" alt="">
          </p>
          <h4 class="extrabold no-pm xs-mt">Internship</h4>
          <h5>Learn to work starting from college</h5>
          <!-- Price -->
          <h3 class="hide-comp">
            ₹
            <span class="text-lg2 extrabold">19</span><span class="merriweather italic light font-17">Monthly</span>
          </h3>
        </div>

        <p class="sm-15">
          In addition, with these knowledges we also provide an internships to the students on the UAV fields at
          efficient manner. Not only on the UAV fields we provide internships on different departments related for the
          Aeronautical Engineering students.
        </p>

        <!-- List -->
        <ul class="hide-comp basic-list list-lg xs-mb">
          <li>PREMIUM PROFILE LISTING</li>
          <li>UNLIMITED FILE ACCESS</li>
          <li>FREE APPOINTMENTS</li>
          <li>2 SUBDOMAINS</li>
        </ul>
        <!-- <div>
          <a href="#" class="xxs-py block font-11 uppercase white bold bg-colored bg-colored-hover slow">Select
            This Plan</a>
        </div> -->
      </div>
    </div>

    <!-- Box -->
    <div>
      <!-- Box Border -->
      <div
        class="border-1 border-gray no-border-bottom radius o-hidden slow bg-gradient1 white bs-light-hover relative">
        <!-- Mark -->
        <div class="basic-mark white radius bg-danger">
          Hot!
        </div>
        <div class="xs-py">
          <p>
            <img src="assets/images/research-concept.png" alt="">
          </p>
          <h4 class="extrabold no-pm xs-mt">UAV Research Works/ Fabrication</h4>
          <h5>Hello scientist, lets do a research</h5>
          <!-- Price -->
          <h3 class="hide-comp">
            ₹
            <span class="text-lg2 extrabold">39</span><span class="merriweather italic light font-17">Monthly</span>
          </h3>
        </div>

        <p class="sm-15">
          We support and complete the final year students’ projects & also the mini-projects for second- & third-year
          students. Also execute your innovation project ideas by implementing on the protype & models in proper
          methodology.
        </p>
        <!-- List -->
        <ul class="hide-comp basic-list list-lg xs-mb">
          <li>PREMIUM PROFILE LISTING</li>
          <li>UNLIMITED FILE ACCESS</li>
          <li>FREE APPOINTMENTS</li>
          <li>2 SUBDOMAINS</li>
        </ul>
        <!-- <div>
          <a href="#" class="xxs-py block font-11 uppercase white bold bg-dark3 slow">Select This Plan</a>
        </div> -->
      </div>
    </div>

    <!-- Box -->
    <div>
      <!-- Box Border -->
      <div class="gray8 border-1 border-gray no-border-bottom radius o-hidden slow bs-light-hover relative">
        <!-- Mark -->
        <!-- <div class="basic-mark white radius bg-warning">
          Hot!
        </div> -->
        <div class="xs-py">
          <p>
            <img src="assets/images/implant-training-concept.png" alt="">
          </p>
          <h4 class="extrabold no-pm xs-mt">Implant Training</h4>
          <h5>Industrial knowledge @ very low time</h5>
          <!-- Price -->
          <h3 class="hide-comp">
            ₹
            <span class="text-lg2 extrabold">59</span><span class="merriweather italic light font-17">Monthly</span>
          </h3>
        </div>

        <p class="sm-15">
          In addition, with these knowledges we also provide an implant training to the students on the UAV fields at
          efficient manner. Not only on the UAV fields we provide implant training on different departments related for
          the Aeronautical Engineering students.
        </p>

        <!-- List -->
        <ul class="hide-comp basic-list list-lg xs-mb">
          <li>PREMIUM PROFILE LISTING</li>
          <li>UNLIMITED FILE ACCESS</li>
          <li>FREE APPOINTMENTS</li>
          <li>2 SUBDOMAINS</li>
        </ul>
        <!-- <div>
          <a href="#" class="xxs-py block font-11 uppercase white bold bg-colored bg-colored-hover slow">Select
            This Plan</a>
        </div> -->
      </div>
    </div>

  </div>
</section>

<section id="training">
  <!-- Divider -->
  <div class="divider-1 font-11 uppercase container extrabold mt">
    <span>Seminars</span>
  </div>
  <div class="t-center">
    <p class="dark light no-pm font-20">Vimanika Technovatorz cover the following seminar topic related.</p>
  </div>
  <div class="container t-center gray8 sm-py">

    <div id="items" class="fadeInUp">

      <!-- Box -->
      <a [routerLink]="['/training']" routerLinkActive="router-link-active" class="cbp-item">
        <div class="qdr-hover-4 block">
          <div class="xs-px xs-py bs-light-hover slow animated bg-white border-1 border-gray2" data-animation="fadeInUp"
            data-animation-delay="100">
            <h4 class="bold-subtitle">Aero Modelling</h4>
          </div>
        </div>
      </a>

      <!-- Box -->
      <a [routerLink]="['/training']" routerLinkActive="router-link-active" class="cbp-item">
        <div class="qdr-hover-4 block">
          <div class="xs-px xs-py bs-light-hover slow animated bg-white border-1 border-gray2" data-animation="fadeInUp"
            data-animation-delay="50">
            <!-- Mark -->
            <div class="basic-mark bold uppercase font-10 radius bg-danger white">
              Hot!
            </div>
            <h4 class="bold-subtitle">UAV Field</h4>
          </div>
        </div>
      </a>

      <!-- Box -->
      <a [routerLink]="['/training']" routerLinkActive="router-link-active" class="cbp-item">
        <div class="qdr-hover-4 block">
          <div class="xs-px xs-py bs-light-hover slow animated bg-white border-1 border-gray2" data-animation="fadeInUp"
            data-animation-delay="100">
            <h4 class="bold-subtitle">CFD</h4>
          </div>
        </div>
      </a>

      <!-- Box -->
      <a [routerLink]="['/training']" routerLinkActive="router-link-active" class=" cbp-item">
        <div class="qdr-hover-4 block">
          <div class="xs-px xs-py bs-light-hover slow animated bg-white border-1 border-gray2" data-animation="fadeInUp"
            data-animation-delay="150">
            <h4 class="bold-subtitle">Ansys Workbench</h4>
          </div>
        </div>
      </a>

      <!-- Box -->
      <a [routerLink]="['/training']" routerLinkActive="router-link-active" class=" cbp-item">
        <div class="qdr-hover-4 block">
          <div class="xs-px xs-py bs-light-hover slow animated bg-white border-1 border-gray2" data-animation="fadeInUp"
            data-animation-delay="50">
            <h4 class="bold-subtitle">Designing software</h4>
          </div>
        </div>
      </a>

      <!-- Box -->
      <a [routerLink]="['/training']" routerLinkActive="router-link-active" class=" cbp-item">
        <div class="qdr-hover-4 block">
          <div class="xs-px xs-py bs-light-hover slow animated bg-white border-1 border-gray2" data-animation="fadeInUp"
            data-animation-delay="50">
            <!-- Mark -->
            <div class="basic-mark bold uppercase font-10 radius bg-danger white">
              Hot!
            </div>
            <h4 class="bold-subtitle">Job opportunity on Aero related fields</h4>
          </div>
        </div>
      </a>

      <!-- Box -->
      <a [routerLink]="['/training']" routerLinkActive="router-link-active" class=" cbp-item">
        <div class="qdr-hover-4 block">
          <div class="xs-px xs-py bs-light-hover slow animated bg-white border-1 border-gray2" data-animation="fadeInUp"
            data-animation-delay="50">
            <h4 class="bold-subtitle">Drone technology on day-to-day life</h4>
          </div>
        </div>
      </a>

      <!-- Box -->
      <a [routerLink]="['/training']" routerLinkActive="router-link-active" class=" cbp-item">
        <div class="qdr-hover-4 block">
          <div class="xs-px xs-py bs-light-hover slow animated bg-white border-1 border-gray2" data-animation="fadeInUp"
            data-animation-delay="50">
            <h4 class="bold-subtitle">Advanced drone technology</h4>
          </div>
        </div>
      </a>

      <!-- Box -->
      <a [routerLink]="['/training']" routerLinkActive="router-link-active" class=" cbp-item">
        <div class="qdr-hover-4 block">
          <div class="xs-px xs-py bs-light-hover slow animated bg-white border-1 border-gray2" data-animation="fadeInUp"
            data-animation-delay="50">
            <h4 class="bold-subtitle">Micro, mini drone</h4>
          </div>
        </div>
      </a>

    </div>

  </div>
  <!--
  <div class="container sm-mt">




  </div> -->
</section>
