<nav id="navigation" class="white-nav sticky shrink modern hover4 radius-drop" data-offset="70">

  <!-- Columns -->
  <div class="columns clearfix container-lg">
      <!-- Logo -->
      <div class="logo">
          <a href="#pagetop">
              <img src="assets/images/logos/logo.png" alt="Vinmika Logo">
              <!-- Retina Logo -->
              <img src="assets/images/logos/logo.png" class="retina-logo" alt="Vinmika Logo">
          </a>
      </div>
      <!-- Right Elements; Nav Button, Language Button, Search .etc -->
      <div class="nav-elements">
          <ul class="clearfix nav t-center-sm normal">
              <!-- External Button -->
              <li>
                  <!-- button goes here -->
              </li>
              <!-- External Button -->
              <li>
                  <!-- button goes here -->
              </li>
          </ul>
      </div>
      <!-- End Navigation Elements -->
      <!-- Navigation Menu -->
      <div class="nav-menu">
          <ul class="nav clearfix uppercase">
              <li><a routerLink="/home">Home</a></li>
              <li><a routerLink="/service" class="stay">Services</a></li>
              <li><a routerLink="/life-style" class="stay">Life Style</a></li>
              <li><a routerLink="/training" class="stay">Training</a></li>
              <li><a routerLink="/coe-mou" class="stay">COE/MOU</a></li>
              <li><a routerLink="/course" class="stay">Course</a></li>
              <li><a routerLink="/career" class="stay">Career</a></li>
              <li><a routerLink="/contact" class="stay">Contact Us</a></li>
          </ul>
      </div>
      <!-- End Navigation Menu -->
  </div>
  <!-- End Columns -->
</nav>
