<section id="home" class="sm-py bg-gray1 bb-1 border-gray1 fullwidth">
  <!-- Container -->
  <div class="container">
    <div class="row calculate-height">
      <div class="t-left t-center-xs col-sm-6 col-xs-12">
        <h2 class="normal-subtitle">We're Hiring</h2>
        <h5 class="gray7">Interested send us all the below details to apply.</h5>
      </div>
      <div class="t-right t-center-xs col-sm-6 col-xs-12">
        <ol class="breadcrumb transparent no-pm v-center font-12">
          <li class="breadcrumb-item black-hover gray8 slow"><a routerLink="/home">Home</a></li>
          <li class="breadcrumb-item gray6 c-default">Career</li>
        </ol>
      </div>
    </div>
  </div>
  <!-- End Container -->
</section>

<div class="hide-comp py container">

  <!-- BOXES -->
  <div class="t-center container qdr-col-3 gap-5 gap-10-xs">
    <!-- Box - You can remove .stay class when you add an url -->
    <div>
      <!-- Icon -->
      <div class="inline-block colored1">
        <i class="icon-layers text-lg1"></i>
      </div>
      <h4 class="xxs-mt bold-subtitle">Exclusive Design</h4>
      <p class="gray6">Discovered the undoubtable source.</p>
      <p class="xxs-mt gray6 font15">There are many variations of passages of Lorem load Ipsum available, predefined but
        the majority have suffe alteration in some form, by injected humour.</p>
    </div>
    <!-- End Box -->
    <!-- Box - You can remove .stay class when you add an url -->
    <div>
      <!-- Icon -->
      <div class="inline-block colored1">
        <i class="icon-rocket text-lg1"></i>
      </div>
      <h4 class="xxs-mt bold-subtitle">Super Responsive</h4>
      <p class="gray6">Discovered the undoubtable source.</p>
      <p class="xxs-mt gray6 font15">There are many variations of passages of Lorem load Ipsum available, predefined but
        the majority have suffe alteration in some form, by injected humour.</p>
    </div>
    <!-- End Box -->
    <!-- Box - You can remove .stay class when you add an url -->
    <div>
      <!-- Icon -->
      <div class="inline-block colored1">
        <i class="icon-trophy text-lg1"></i>
      </div>
      <h4 class="xxs-mt bold-subtitle">Unlimited Helpers</h4>
      <p class="gray6">Discovered the undoubtable source.</p>
      <p class="xxs-mt gray6 font15">There are many variations of passages of Lorem load Ipsum available, predefined but
        the majority have suffe alteration in some form, by injected humour.</p>
    </div>
    <!-- End Box -->
  </div>

</div>

<!-- Faqs Title -->
<div class="container pt t-center">
  <!-- Title -->
  <h1 class="bold-title">Job Postings</h1>
  <h4 class="dark light no-pm">We have only limitted availability for jobs posted here.</h4>
  <div class="title-strips-over dark"></div>
</div>
<!-- End Faqs Title -->

<!-- Container for details -->
<div class="container sm-mt mb">
  <!-- Row for cols -->
  <div class="row">
    <!-- Col -->
    <div class="col-md-7 col-12">
      <!-- Accordion -->
      <div>
        <!-- Text Button - Trigger -->
        <div data-target="#job-1" class="accordion-toggle-link" data-toggle="collapse">
          System Engineer ( UAV Pilot & System Integration)
        </div>
        <!-- Hidden Text -->
        <div class="accordion-toggle-container">
          <div id="job-1" class="collapsed in">
            <div class="accordion-body xxs-py">
              <p>
                <strong>Location:</strong> Coimbatore -
                <strong>Department:</strong> System Integration
              </p>
              <!-- <p>But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born
                and I will give you.</p>
              <a href="#career_form" class="md-btn click-effect bg-colored qdr-hover-1 white radius xxs-mt">Apply
                Now</a> -->
            </div>
          </div>
        </div>
      </div>
      <!-- End Accordion -->
      <!-- Accordion -->
      <div>
        <!-- Text Button - Trigger -->
        <div data-target="#job-2" class="accordion-toggle-link collapsed" data-toggle="collapse">
          Design Engineer (CAD,CAM & FEA )
        </div>
        <!-- Hidden Text -->
        <div class="accordion-toggle-container">
          <div id="job-2" class="collapse">
            <div class="accordion-body xxs-py">
              <p>
                <strong>Location:</strong> Coimbatore -
                <strong>Department:</strong> Design Department
              </p>
              <!-- <p>But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born
                and I will give you.</p>
              <a href="#career_form" class="md-btn click-effect bg-colored qdr-hover-1 white radius xxs-mt">Apply
                Now</a> -->
            </div>
          </div>
        </div>
      </div>
      <!-- End Accordion -->
      <!-- Accordion -->
      <div>
        <!-- Text Button - Trigger -->
        <div data-target="#job-3" class="accordion-toggle-link collapsed" data-toggle="collapse">
          UAV Pilot & Instructor
        </div>
        <!-- Hidden Text -->
        <div class="accordion-toggle-container">
          <div id="job-3" class="collapse">
            <div class="accordion-body xxs-py">
              <p>
                <strong>Location:</strong> Coimbatore -
                <strong>Department:</strong> Piloting
              </p>
              <!-- <p>But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born
                and I will give you.</p>
              <a href="#career_form" class="md-btn click-effect bg-colored qdr-hover-1 white radius xxs-mt">Apply
                Now</a> -->
            </div>
          </div>
        </div>
      </div>
      <!-- End Accordion -->
      <!-- Accordion -->
      <div>
        <!-- Text Button - Trigger -->
        <div data-target="#job-4" class="accordion-toggle-link collapsed" data-toggle="collapse">
          Microcontroller Programmer
        </div>
        <!-- Hidden Text -->
        <div class="accordion-toggle-container">
          <div id="job-4" class="collapse">
            <div class="accordion-body xxs-py">
              <p>
                <strong>Location:</strong> Coimbatore -
                <strong>Department:</strong> System Integration
              </p>
              <!-- <p>But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born
                and I will give you.</p>
              <a href="#career_form" class="md-btn click-effect bg-colored qdr-hover-1 white radius xxs-mt">Apply
                Now</a> -->
            </div>
          </div>
        </div>
      </div>
      <!-- End Accordion -->
      <!-- Accordion -->
      <div>
        <!-- Text Button - Trigger -->
        <div data-target="#job-5" class="accordion-toggle-link collapsed" data-toggle="collapse">
          Office Assistant, Business Development, Purchase
        </div>
        <!-- Hidden Text -->
        <div class="accordion-toggle-container">
          <div id="job-5" class="collapse">
            <div class="accordion-body xxs-py">
              <p>
                <strong>Location:</strong> Coimbatore -
                <strong>Department:</strong> Business, Administration
              </p>
              <!-- <p>But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born
                and I will give you.</p>
              <a href="#career_form" class="md-btn click-effect bg-colored qdr-hover-1 white radius xxs-mt">Apply
                Now</a> -->
            </div>
          </div>
        </div>
      </div>
      <!-- End Accordion -->
    </div>
    <!-- End Col -->

    <!-- Col -->
    <div class="col-md-5 col-12">
      <!-- Contact Form -->
      <form class="quick_form relative" [formGroup]="careerFrom" (ngSubmit)="sendEmail()" novalidate>
        <!-- Name -->
        <label>Name:*</label>
        <input type="text" name="name" id="name" formControlName="name" required class="classic_form radius">
        <!-- Email -->
        <label>Email:*</label>
        <input type="email" name="email" id="email" formControlName="email" required class="classic_form radius">
        <!-- Half -->
        <div class="clearfix">
          <div class="col-xs-6 pl-0">
            <label>Age:*</label>
            <input type="number" name="age" id="age" formControlName="age" required class="classic_form radius">
          </div>
          <div class="col-xs-6 pr-0">
            <label>Website/Blog:</label>
            <input type="text" name="web" id="web" formControlName="web" class="classic_form radius">
          </div>
        </div>
        <!-- Date -->
        <label>Start Date:*</label>
        <input type="date" name="stDt" id="stDt" formControlName="stDt" required placeholder="Choose a date"
          class="classic_form radius datepicker">
        <!-- Position -->
        <label>Choose a Position:*</label>
        <select name="position" name="pos" id="pos" formControlName="pos" form="career_form" required class="classic_form">
          <option value="">Select a Position</option>
          <option value="System Engineer ( UAV Pilot & System Integration)">System Engineer ( UAV Pilot & System
            Integration)</option>
          <option value="Design Engineer (CAD,CAM & FEA )">Design Engineer (CAD,CAM & FEA )</option>
          <option value="UAV Pilot & Instructor">UAV Pilot & Instructor</option>
          <option value="Microcontroller Programmer">Microcontroller Programmer</option>
          <option value="Office Assistant, Business Development, Purchase">Office Assistant, Business Development,
            Purchase</option>
        </select>
        <!-- Experience -->
        <label>Experience:</label>
        <textarea name="exp" id="exp" formControlName="exp" class="classic_form radius"></textarea>
        <!-- Message -->
        <label>Message:</label>
        <textarea name="msg" id="msg" formControlName="msg" class="classic_form radius big"></textarea>
        <!-- Send Button -->
        <button type="submit" id="submit"
          class="bg-colored white qdr-hover-1 click-effect fullwidth lg-btn uppercase extrabold">Send</button>
        <!-- End Send Button -->
      </form>
    </div>
    <!-- End Col -->
  </div>
  <!-- End Row for cols -->
</div>
