<section id="home" class="sm-py bg-gray1 bb-1 border-gray1 fullwidth">
  <!-- Container -->
  <div class="container">
    <div class="row calculate-height">
      <div class="t-left t-center-xs col-sm-6 col-xs-12">
        <h2 class="normal-subtitle">COE / MOU</h2>
        <!-- <h5 class="gray7">Learn 'n' practice, never get outdated...</h5> -->
      </div>
      <div class="t-right t-center-xs col-sm-6 col-xs-12">
        <ol class="breadcrumb transparent no-pm v-center font-12">
          <li class="breadcrumb-item black-hover gray8 slow"><a routerLink="/home">Home</a></li>
          <li class="breadcrumb-item gray6 c-default">Coe/Mou</li>
        </ol>
      </div>
    </div>
  </div>
  <!-- End Container -->
</section>

<section id="coe/mou" class="md-py">
  <div class="container t-center">
    <h1 class="uppercase fjalla text-lg1 black lh-sm">
      Our valued COE/MOU partners
    </h1>
    <p class="hide-comp gray6 mini-mt lh-lg">
      Contrary to popular belief, Lorem Ipsum is not simply random text. <br class="hidden-xs">
      It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. <br
        class="hidden-xs">
      Richard McClintock, a Latin professor at Hampden-Sydney.
    </p>
  </div>
  <div class="container mt">
    <!-- BOXES -->
    <div class="t-center row clearfix">
      <!-- Box -->
      <div class="col-sm-4 col-xs-12 sm-mt-mobile">
        <div data-toggle="modal" data-target="#modal1"
          class="c-pointer bg-gray1-hover border-1 border-gray2 slow sm-py click-effect dark-effect block">
          <!-- Icon -->
          <div class="inline-block colored1">
            <!-- <i class="icon-badge text-lg1"></i> -->
            <img src="https://lh3.googleusercontent.com/PIHWX5CY6CjYmnsg610CwW7zb2jNM7nYWpEoPOFR0vtJD5sc9CEreQPLGto4bPogwtAh4xi4anTaviUa=s240" alt="" class="text-lg1" width="100px">
          </div>
          <h4 class="xxs-mt bold-subtitle">
            RVE TECHNOLOGIES
          </h4>
          <p class="bold-subtitle lh-sm xxs-mt">
            #38,RAJLAXMI COMPLEX,
            <br class="hidden-xs">
            GROUND FLOOR,BTM 1ST STAGE
            <br class="hidden-xs">
            OPP BTM WATER TANK
            <br class="hidden-xs">
            BANGALORE-560029
          </p>
          <!-- <p class="xxs-mt gray7 font15">There are many variations of passages of <br class="hidden-xs"> Lorem load Ipsum available.</p> -->
          <a href="http://www.rvetechnologies.in/" class="bold-subtitle underline-hover xxs-mt block">
            www.rvetechnologies.in
          </a>
          <a href="tel:(080)-420-66077" class="bold-subtitle underline-hover mini-mt block">
            (080)-420-66077
          </a>
          <a href="tel:+91 9902969232" class="bold-subtitle underline-hover mini-mt block">
            +91 9902969232
          </a>
        </div>
      </div>
      <!-- End Box -->
    </div>

  </div>
</section>
