<section id="pagenotfound" class="fullscreen height-auto-mobile dark t-center bg-soft bg-soft-white5">

  <div class="v-center v-normal-mobile py-mobile">

      <img src="assets/images/404_01.png">

      <h2 class="bold-title xs-mt">Ooops! Page Not Found...</h2>
      <h5 class="gray7 mini-mt semibold">The page you were looking for was not found.</h5>

      <div class="xs-mt bold uppercase">
          <a routerLink="/home" class="xl-btn white radius-lg font-12 qdr-hover-6 bs-lg-hover slow" data-bgcolor="#3c5848">Go Back Home</a>
      </div>

  </div>

</section>
