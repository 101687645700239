<footer id="footer" class="classic_footer">
  <!-- Container -->
  <div class="container footer-body">
    <div class="row clearfix">
      <!-- Column -->
      <div class="col-md-8 col-sm-6 col-xs-12 sm-mb-mobile">
        <!-- Title -->
        <h6 class="uppercase white extrabold">Vimanika Technovatorz</h6>
        <h6 class="sm-mt bold gray8">ABOUT US</h6>
        <p class="mini-mt">Vimanika Technovatorz has established on Jan 2019 at Coimbatore, Tamil Nadu by Aeronautical
          Engineers. It is mainly developed on the basis of training firm by providing quality training to the students
          on Aero-modeling, UAV piloting, 3D Printing and also we afford a training courses on Design and Simulation
          software’s. </p>
        <h6 class="xs-mt bold gray8"><i class="fa fa-map-marker mini-mr"></i>OUR ADDRESS</h6>
        <p class="mini-mt">: 4/5A, S.R.S Puram, Sulur <br class="hidden-xs"> Coimbatore, Tamil Nadu, India- 641402.
        </p>
        <!-- Google Map -->
        <a href="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2624.9916256937586!2d2.2922926156743895!3d48.858370079287475!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e66e2964e34e2d%3A0x8ddca9ee380ef7e0!2sEyfel+Kulesi!5e0!3m2!1str!2s!4v1491905379246"
          data-iframe="true" class="lightbox underline-hover colored-hover">
          Find us on Google Map
        </a>

      </div>
      <!-- Column -->
      <div class="col-md-4 col-sm-6 col-xs-12">
        <!-- Title -->
        <h5 class="hide-comp uppercase white extrabold">SUBSCRIBE US</h5>
        <!-- Sub Title -->
        <h6 class="hide-comp sm-mt bold gray8">GET UPDATED</h6>
        <p class="hide-comp mini-mt">The standard chunk of Lorem Ipsum used.</p>
        <div id="newsletter-form" class="hide-comp footer-newsletter clearfix xs-mt">
          <form id="newsletter_form" name="newsletter_form" method="post" action="php/newsletter.php">
            <input type="email" name="n-email" id="n-email" required placeholder="Add your E-Mail address"
              class="font-12 radius-lg form-control">
            <button type="submit" id="n-submit" name="submit"
              class="btn-lg fullwidth radius-lg bg-colored white qdr-hover-6 click-effect bold font-12">SUBSCRIBE</button>
          </form>
        </div>
        <h6 class="xs-mt bold gray8"><i class="fa fa-envelope mini-mr"></i>CONTACT US</h6>
        <p class="mini-mt">Mobile: <a href="tel:+919659191486" class="underline-hover colored-hover">+91 9659191486</a>
        </p>
        <p class="mini-mt">Mobile: <a href="tel:+918072015436" class="underline-hover colored-hover">+91 8072015436</a>
        </p>
        <a href="mailto:Vimanikatechnovatorz@gmail.com"
          class="underline-hover colored-hover">Vimanikatechnovatorz@gmail.com</a>
        <!-- End Form -->
        <h6 class="xs-mt xxs-mb bold gray8">FOLLOW US</h6>
        <!-- <a href="#" class="icon-xs radius bg-dark facebook white-hover slow1"><i class="fa fa-facebook"></i></a> -->
        <a href="https://www.linkedin.com/in/vimanika-technovatorz-93823a202/"
          class="icon-xs radius bg-dark linkedin white-hover slow1"><i class="fa fa-linkedin"></i></a>
        <a href="https://www.instagram.com/vimanika_technovatorz/"
          class="icon-xs radius bg-dark instagram white-hover slow1"><i class="fa fa-instagram"></i></a>
        <!-- <a href="#" class="icon-xs radius bg-dark pinterest white-hover slow1"><i class="fa fa-pinterest"></i></a> -->
      </div>
      <!-- End Column -->
    </div>
  </div>
  <!-- End Container -->
  <!-- Footer Bottom -->
  <div class="footer-bottom">
    <div class="container">
      <div class="row clearfix calculate-height t-center-xs">
        <div class="col-sm-6 col-xs-12 table-im t-left height-auto-mobile t-center-xs">
          <div class="v-middle">
            <img src="assets/images/logos/logo.png" alt="logo icon" class="logo">
          </div>
        </div>
        <!-- Bottom Note -->
        <div class="col-sm-6 col-xs-12 table-im t-right height-auto-mobile t-center-xs xxs-mt-mobile">
          <p class="v-middle">
            <!-- data-toggle="modal" data-target="#modal-1" -->
            <a href="https://www.termsandconditionsgenerator.com/live.php?token=FmhzSLRCS7Jqp92gFDm7wFqZDgXVODZa"
              target="_blank" class="gray6-hover underline-hover">Term and
              Condition</a> |
            <a href="https://www.privacypolicygenerator.info/live.php?token=GDt4GsbhTEUAn4YEPQlE2oKyCmAl2NZO"
              target="_blank" class="gray6-hover underline-hover">Privacy Policy</a> <br class="hidden-xs">
             © 2021.<!-- Developed By
            <a href="https://github.com/karthi-21/" target="_blank"
              class="colored-hover underline-hover">Karthikeyan</a>. -->
          </p>
        </div>
      </div>
    </div>
  </div>
</footer>

<!-- Modal 1 -->
<div id="modal-1" class="modal middle-modal fade" tabindex="-1" role="dialog">
  <!-- Container -->
  <div class="modal-dialog container bg-white radius o-hidden" role="document">
    <!-- Modal Content -->
    <div class="modal-content">
      <!-- Close Button for modal -->
      <div class="close fa fa-close" data-dismiss="modal"></div>
      <!-- Left, Image area -->
      <div class="modal-img col-md-5 col-12" data-background="assets/images/blocks/modal_01.jpg"></div>
      <!-- Right, Details -->
      <div class="modal-details col-md-7 offset-md-5 col-12">
        <!-- Title -->
        <h2 class="light">Classic Modal</h2>
        <!-- Paragraph -->
        <h4 class="light xxs-mt">
          All the Lorem Ipsum generators on the Internet tend.
        </h4>
        <!-- Listing -->
        <ul class="list-group xs-my font-15">
          <li class="list-group-item justify-content-between">
            All the Lorem Ipsum generators on the Internet.
          </li>
          <li class="list-group-item justify-content-between list-group-item-warning">
            Latin words, combined with a handful of model sentence.
          </li>
        </ul>
        <!-- Paragraph -->
        <p class="paragraph xs-mb gray6 lh-normal">
          All the Lorem Ipsum generators on the Internet. It is a long established fact that a reader will be distracted
          by the readable content of a page when looking at its layout. The point of using.
        </p>
        <!-- Button -->
        <a href="#" target="_blank"
          class="lg-btn font-12 long-btn xxs-mt bg-gradient1 bs-lg-hover white radius-lg qdr-hover-6 slow extrabold">
          SEE MORE DETAILS
        </a>
      </div>
    </div>
    <!-- End Modal Content -->
  </div>
  <!-- End Container -->
</div>
<!-- End Modal 1 -->

<!-- Quick Contact Form -->
<div class="quick-contact-form dark border-colored">
  <h5 class="uppercase t-center extrabold h5">Drop us a message</h5>
  <p class="t-center normal h5 lh-normal">You're in the right place! Just drop us a message. How can we help?</p>
  <!-- Contact Form -->
  <form class="quick_form" name="quick_form" [formGroup]="quickMsg" (ngSubmit)="sendEmail()" novalidate>
    <!-- Name -->
    <input type="text" name="qname" id="qname" formControlName="qname" required placeholder="Name" class="no-mt">
    <!-- Email -->
    <input type="email" name="qemail" id="qemail" formControlName="qemail" required placeholder="E-Mail">
    <!-- Message -->
    <textarea name="qmessage" id="qmessage" formControlName="qmessage" required placeholder="Message"></textarea>
    <!-- Send Button -->
    <button type="submit" id="qsubmit"
      class="bg-colored white click-effect qdr-hover-2-rotate uppercase extrabold">Send</button>
    <!-- End Send Button -->
  </form>
</div>
<!-- Contact us button -->
<div class="drop-msg hide-on-home dark"><i class="fa fa-envelope-o"></i></div>
<!-- Back To Top -->
<a id="back-to-top" class="dark" href="#top"><i class="fa fa-angle-up"></i></a>

<!-- Messages for contact form -->
<div id="error_message" class="clearfix">
  <i class="fa fa-warning"></i>
  <span>Validation error occured. Please enter the fields and submit it again.</span>
</div>
<!-- Submit Message -->
<div id="submit_message" class="clearfix">
  <i class="fa fa-check"></i>
  <span>Thank You ! Your email has been delivered.</span>
</div>
