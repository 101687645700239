<section id="service" class="sm-py bg-gray1 bb-1 border-gray1 fullwidth">
  <!-- Container -->
  <div class="container">
    <div class="row calculate-height">
      <div class="t-left t-center-xs col-sm-6 col-xs-12">
        <h2 class="normal-subtitle">Our Services</h2>
        <!-- <h5 class="gray7">Modifiable examples with default styles.</h5> -->
      </div>
      <div class="t-right t-center-xs col-sm-6 col-xs-12">
        <ol class="breadcrumb transparent no-pm v-center font-12">
          <li class="breadcrumb-item black-hover gray8 slow"><a routerLink="/home">Home</a></li>
          <li class="breadcrumb-item gray6 c-default">Service</li>
        </ol>
      </div>
    </div>
  </div>
  <!-- End Container -->
</section>

<section class="border-gray1 sm-pt">

  <div id="services">

    <!-- Boxes -->
    <div class="boxes container t-left t-center-sm">

      <div class="row">

        <!-- Box -->
        <div class="col-md-4 col-12 sm-mt">
          <div class="row no-mx">
            <!-- Icon -->
            <div class="col-1 t-center-sm no-pm">
              <img src="assets/images/icons/design.png" class="icon" alt="design">
              <!-- <i class="icon-map h2 colored1"></i> -->
            </div>
            <div class="col-11 no-pr">
              <h4 class="bold-subtitle">DESIGN & ANALYSIS OUTSOURCING</h4>
              <p class="gray6 normal">R&D SERVICES</p>
            </div>
            <div class="col-12 xxs-mt no-pm">
              <p class="gray6 normal">
                We are here to specifically provide outsourcing on 3D design layouts & Analysis solution
                for the Government, Non-Government & Private Companies.
              </p>
            </div>
          </div>
        </div>

        <!-- Box -->
        <div class="col-md-4 col-12 sm-mt">
          <div class="row no-mx">
            <!-- Icon -->
            <div class="col-1 t-center-sm no-pm">
              <img src="assets/images/icons/uav.png" class="icon" alt="design">
              <!-- <i class="icon-globe-alt h2 colored1"></i> -->
            </div>
            <div class="col-11 no-pr">
              <h4 class="bold-subtitle">UAV FABRICATION PRODUCTS</h4>
              <p class="gray6 normal">R&D SERVICES</p>
            </div>
            <div class="col-12 xxs-mt no-pm">
              <p class="gray6 normal">
                We make fabrication products on various UAV models like Quadcopter, Hexacopter,
                Octocopter, VTOL (Vertical Take-off Landing), Quad Co-axial, Hexa Co-Axial, Fixed Wing
                models, etc. We also fabricate customized Drones for various applications like Agriculture,
                Aerial Photography, surveillance, Shipping and delivery etc.
              </p>
            </div>
          </div>
        </div>

        <!-- Box -->
        <div class="col-md-4 col-12 sm-mt">
          <div class="row no-mx">
            <!-- Icon -->
            <div class="col-1 t-center-sm no-pm">
              <img src="assets/images/icons/3d.png" class="icon" alt="design">
              <!-- <i class="icon-home h2 colored1"></i> -->
            </div>
            <div class="col-11 no-pr">
              <h4 class="bold-subtitle">3D PRINTING SERVICES</h4>
              <p class="gray6 normal">R&D SERVICES</p>
            </div>
            <div class="col-12 xxs-mt no-pm">
              <p class="gray6 normal">
                We are here to fabricate the 3D printed parts for various companies based on their
                required custom designs and also for individuals who wants to make their gifts very unique
                for their special ones.
              </p>
            </div>
          </div>
        </div>

        <!-- Box -->
        <div class="col-md-4 col-12 sm-mt">
          <div class="row no-mx">
            <!-- Icon -->
            <div class="col-1 t-center-sm no-pm">
              <img src="assets/images/icons/project.png" class="icon" alt="design">
              <!-- <i class="icon-options-vertical h2 colored1"></i> -->
            </div>
            <div class="col-11 no-pr">
              <h4 class="bold-subtitle">COLLEGE PROJECTS</h4>
              <p class="gray6 normal">ENGINEERING SERVICES</p>
            </div>
            <div class="col-12 xxs-mt no-pm">
              <p class="gray6 normal">
                Vimanika Technovatorz is here to support and develop a technically competent project
                related to the stream of Aeronautical, Mechanical and Electrical Communication Systems (AI).
                In addition, with this, we enhance students' skills by offering practical hands
                on training methods to build their technical knowledge in order to successfully achieve their p
                rofessional career.College projects that includes Design project, fabrication model and
                prototypes that is relevant to the stream referred above. We mainly focus on the Aeronautical
                department related projects in the growing field of drone technology.
              </p>
            </div>
          </div>
        </div>

        <!-- Box -->
        <div class="col-md-4 col-12 sm-mt">
          <div class="row no-mx">
            <!-- Icon -->
            <div class="col-1 t-center-sm no-pm">
              <img src="assets/images/icons/research.png" class="icon" alt="design">
              <!-- <i class="icon-screen-desktop h2 colored1"></i> -->
            </div>
            <div class="col-11 no-pr">
              <h4 class="bold-subtitle">RESEARCH PROJECTS</h4>
              <p class="gray6 normal">ENGINEERING SERVICES</p>
            </div>
            <div class="col-12 xxs-mt no-pm">
              <p class="gray6 normal">
                We support the development of new ideas to encourage yours research work by
                providing supports like concept development, manufacturing, testing etc till successful
                completion of your research projects.
              </p>
            </div>
          </div>
        </div>

        <!-- Box -->
        <div class="col-md-4 col-12 sm-mt">
          <div class="row no-mx">
            <!-- Icon -->
            <div class="col-1 t-center-sm no-pm">
              <img src="assets/images/icons/journal.png" class="icon" alt="design">
              <!-- <i class="icon-plane h2 colored1"></i> -->
            </div>
            <div class="col-11 no-pr">
              <h4 class="bold-subtitle">JOURNAL PUBLISHING</h4>
              <p class="gray6 normal">ENGINEERING SERVICES</p>
            </div>
            <div class="col-12 xxs-mt no-pm">
              <p class="gray6 normal">
                We are here to support you for publishing your Research article/ Journal/ Book chapter in
                various Scopus / SCI- indexed or Anna University annexure journals. We will guide you from
                identifying your Thrust Area, Title of your interested Research field, Content development,
                Poof reading, grammatical check and Plagiarism. Even we will direct you to choose your
                appropriate journals based on your research topic.
              </p>
            </div>
          </div>
        </div>

      </div>

    </div>
    <!-- End Boxes -->
  </div>
</section>

<section id="content" class="md-pt sm-pb xxs-mb">

  <div class="container t-center">
    <!-- Title -->
    <h1 class="bold-title">Best Rated Service</h1>
    <h4 class="dark light no-pm">Interested send us all the below details to apply.</h4>
    <div class="title-strips-over dark"></div>
  </div>
  <!-- Container to details -->
  <div class="container clearfix">
    <div class="row">
      <!-- Left Image -->
      <div class="col-12 col-md-6 col-lg-6">
        <!-- <a href="assets/images/CFX.mp4" class="lightbox block block-img"> -->
          <img src="assets/images/service-content.png" class="block block-img" width="100%" alt="content service design">
        <!-- </a> -->
      </div>
      <!-- Right Texts -->
      <div class="col-12 col-md-6 col-lg-6 xxs-py">
        <h5 class="gray6">There are many variations of passages</h5>
        <h1 class="bold-title ">
          Our first goal is to <span class="playfair italic">provide the</span> <br class="hidden-xs">
          highest <span class="playfair italic">quality projects.</span>
        </h1>
        <h4 class="xs-my gray6">
          It is a long established fact that a reader will be distracted
          content of a page when looking at its layout.
        </h4>
        <p class="lh-lg gray7">
          There are many variations of servies we provide.
        </p>
        <!-- List -->
        <ul class="list list-circle list-side xs-pt gray7 underline-hover-links font-14 bold-subtitle">
          <li>
            <span class="icon">1</span>
            <a routerLink="#">DESIGN & ANALYSIS OUTSOURCING</a>
          </li>
          <li>
            <span class="icon">2</span>
            <a routerLink="#">UAV FABRICATION PRODUCTS</a>
          </li>
          <li>
            <span class="icon">3</span>
            <a routerLink="#">3D PRINTING SERVICES</a>
          </li>
          <li>
            <span class="icon">4</span>
            <a routerLink="#">COLLEGE PROJECTS</a>
          </li>
          <li>
            <span class="icon">5</span>
            <a routerLink="#">RESEARCH PROJECTS</a>
          </li>
          <li>
            <span class="icon">6</span>
            <a routerLink="#">JOURNAL PUBLISHING</a>
          </li>
          <li>
            <span class="icon">7</span>
            <a routerLink="#">ENGINEERING SERVICES</a>
          </li>
          <li>
            <span class="icon">8</span>
            <a routerLink="#">R&D SERVICES</a>
          </li>
          <li>
            <span class="icon">9</span>
            <a routerLink="#">UAV DRONE Parts & Services will be available</a>
          </li>
        </ul>
      </div>
      <!-- End Right Texts -->
    </div>
  </div>
  <!-- End Container to details -->
</section>
