<section id="home" class="md-py">
  <!-- Container for title -->
  <div class="container t-center">
    <h1 class="uppercase fjalla text-lg1 black lh-sm">
      do you need a solution ?
      <br class="hidden-xs">
      Contact us now
    </h1>
    <p class="hide-comp gray6 mini-mt lh-lg">
      Contrary to popular belief, Lorem Ipsum is not simply random text. <br class="hidden-xs">
      It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. <br
        class="hidden-xs">
      Richard McClintock, a Latin professor at Hampden-Sydney.
    </p>
  </div>

  <div class="container mt">
    <!-- BOXES -->
    <div class="t-center row clearfix">
      <!-- Box -->
      <div class="hide-comp col-sm-4 col-xs-12 sm-mt-mobile">
        <div data-toggle="modal" data-target="#modal1"
          class="c-pointer bg-gray1-hover border-1 border-gray2 slow sm-py click-effect dark-effect block">
          <!-- Icon -->
          <div class="inline-block colored1">
            <i class="icon-badge text-lg1"></i>
          </div>
          <!-- <h4 class="xxs-mt bold-subtitle">
            Kishan Kumar
          </h4> -->
          <p class="bold-subtitle lh-sm xxs-mt">
            Chennai, Tamil Nadu, India - 600302.
          </p>
          <!-- <p class="xxs-mt gray7 font15">There are many variations of passages of <br class="hidden-xs"> Lorem load Ipsum available.</p> -->
          <a href="mailto:Vimanikatechnovatorz@gmail.com" class="bold-subtitle underline-hover xxs-mt block uppercase">
            Vimanikatechnovatorz@gmail.com
          </a>
          <a href="tel:+918072015436" class="bold-subtitle underline-hover mini-mt block">
            +91 8072015436
          </a>
        </div>
      </div>
      <!-- End Box -->
      <!-- Box -->
      <div class="col-sm-6 col-xs-12 sm-mt-mobile">
        <div data-toggle="modal" data-target="#modal1"
          class="c-pointer bg-gray1-hover border-1 border-gray2 slow sm-py click-effect dark-effect block">
          <!-- Icon -->
          <div class="inline-block colored1">
            <i class="icon-home text-lg1"></i>
          </div>
          <!-- <h4 class="xxs-mt bold-subtitle">
            S.L. PRADEEP KUMAR
          </h4> -->
          <p class="bold-subtitle lh-sm xxs-mt">
            4/5A, S.R.S Puram, Sulur, <br class="hidden-xs"> Coimbatore, Tamil Nadu, India - 641402.
          </p>
          <!-- <p class="xxs-mt gray7 font15">There are many variations of passages of <br class="hidden-xs"> Lorem load Ipsum available.</p> -->
          <a href="mailto:Vimanikatechnovatorz@gmail.com" class="bold-subtitle underline-hover xxs-mt block uppercase">
            Vimanikatechnovatorz@gmail.com
          </a>
          <a href="tel:+91 9659191486" class="bold-subtitle underline-hover mini-mt block">
            +91 9659191486
          </a>
        </div>
      </div>
      <!-- End Box -->
      <!-- Box -->
      <div class="col-sm-6 col-xs-12 sm-mt-mobile">
        <div data-toggle="modal" data-target="#modal2"
          class="c-pointer bg-gray1-hover border-1 border-gray2 slow sm-py click-effect dark-effect block">
          <!-- Icon -->
          <div class="inline-block colored1">
            <i class="icon-badge text-lg1"></i>
          </div>
          <!-- <h4 class="xxs-mt bold-subtitle">
            KISHAN KUMAR
          </h4> -->
          <p class="bold-subtitle lh-sm xxs-mt">
            Salem, Tamil Nadu, <br class="hidden-xs"> India - 636106.
          </p>
          <!-- <p class="xxs-mt gray7 font15">There are many variations of passages of <br class="hidden-xs"> Lorem load Ipsum available.</p> -->
          <a href="mailto:Vimanikatechnovatorz@gmail.com" class="bold-subtitle underline-hover xxs-mt block uppercase">
            Vimanikatechnovatorz@gmail.com
          </a>
          <a href="tel:+91 8072015436" class="bold-subtitle underline-hover mini-mt block">
            +91 8072015436
          </a>
        </div>
      </div>
      <!-- End Box -->
    </div>

  </div>
</section>


<section id="contact" class="py relative border-1 border-gray1 bg-gray1">
  <!-- Divider - Parent section should have .relative class -->
  <a href="#contact"
    class="icon-divider icon-md font-18 extrabold-subtitle gray5 bg-white border-1 border-gray1 circle">
    <i class="icon-envelope-letter"></i>
  </a>

  <div class="hide-comp t-center">
    <p class="gray6">It is a long established fact that.</p>
    <h1 class="bold-title lh-sm">
      Drop Us a Message
    </h1>
  </div>

  <!-- Container for form -->
  <div class="hide-comp container">
    <!-- Form -->
    <div id="form" class="sm-mt t-center gray7">
      <!-- Contact Form -->
      <!-- method="post" action="assets/php/mail.php"  -->
      <form id="contact_form" name="contact_form">
        <!-- Half Inputs -->
        <div class="half clearfix">
          <!-- Name -->
          <input type="text" name="name" id="name" required placeholder="Name"
            class="classic_form big radius-lg bg-gray3 bs-light-focus">
          <!-- Phone -->
          <input type="numbers" name="phone" id="phone" required placeholder="Phone"
            class="classic_form big radius-lg bg-gray3 bs-light-focus">
        </div>
        <!-- Email -->
        <input type="email" name="email" id="email" required placeholder="E-Mail"
          class="classic_form big radius-lg bg-gray3 bs-light-focus">
        <!-- Message -->
        <textarea name="message" id="message" placeholder="Message"
          class="classic_form big radius bg-gray3 bs-light-focus"></textarea>
        <!-- Half Inputs -->
        <div class="half clearfix">
          <select name="subject" form="contact_form" required
            class="classic_form big radius-lg bg-gray3 bs-light-focus">
            <option value="">Select a Subject</option>
            <option value="opt1">Option 1</option>
            <option value="opt2">Option 2</option>
            <option value="opt3">Option 3</option>
          </select>
          <!-- Verify -->
          <input type="text" name="verify" id="verify" required
            class="classic_form f-right big radius-lg bg-gray3 bs-light-focus">

        </div>
        <!-- Send Button -->
        <button (click)="sendEmail()"
          class="bg-colored1 xl-btn font-12 long-btn xxs-mt slow width-auto click-effect white bold qdr-hover-6 radius-lg">SEND
          MESSAGE</button>
      </form>
      <!-- End Form -->
    </div>
    <!-- End #form div -->
  </div>
  <!-- Divider -->
  <div class="divider-1 font-11 gray6 uppercase container extrabold sm-mt xs-mb">
    <span>Stalk Us</span>
  </div>

  <!-- Buttons -->
  <div class="qdr-col-2 gap-15 container t-center">
    <!-- Button -->
    <!-- <div><a href="#"
        class="xl-btn block-im qdr-hover-3 fa fa-facebook facebook-bg white radius-lg bs-inset-hover qdr-hover-4">Facebook</a>
    </div> -->
    <!-- Button -->
    <!-- <div><a href="#"
        class="xl-btn block-im qdr-hover-3 fa fa-twitter twitter-bg white radius-lg bs-inset-hover qdr-hover-4">Twitter</a>
    </div> -->
    <!-- Button -->
    <div><a href="https://www.instagram.com/vimanika_technovatorz"
        class="xl-btn block-im qdr-hover-3 fa fa-instagram instagram-bg white radius-lg bs-inset-hover qdr-hover-4">Instagram</a>
    </div>
    <!-- Button -->
    <div><a href="http://www.linkedin.com/in/vimanika-technovatorz-93823a202"
        class="xl-btn block-im qdr-hover-3 fa fa-linkedin linkedin-bg white radius-lg bs-inset-hover qdr-hover-4">Linkedin</a>
    </div>
    <!-- Button -->
    <!-- <div><a href="#"
        class="xl-btn block-im qdr-hover-3 fa fa-google-plus google-plus-bg white radius-lg bs-inset-hover qdr-hover-4">Google+</a>
    </div> -->
  </div>

</section>
